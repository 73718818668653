:root {
  --card-height-small: 250px;
  --card-height-big:380px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
}
.main-wrapper {
  background-image: url(./assets/images/background/background1.png);
}
.page-wrapper {
  position: relative;
  min-height: 100vh !important;
  overflow: auto;
  background-color: #fff !important;
}
.form-item {
  margin: 1rem 0;
  font-weight: 700;
}
.form-item p {
  font-weight: 400;
}
.form-input {
  border: 2px solid rgba(84, 152, 195, 0.5) !important;
  text-decoration: black !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  width: 100%;
}
.btn-submit {
  background-color: rgba(84, 152, 195, 0.7);
  padding: 0.5rem 2rem;
  margin: 0.5rem;
  border: none;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
}
.btn-submit:hover {
  border: 0.5px solid #000;
}
.btn-submit:active {
  background-color: rgba(84, 152, 195, 0.7);
  border: 0.5px solid #000;
  opacity: 0.8;
}
.ant-layout-footer {
  background-color: #fff !important;
  padding: 12px 50px !important;
}
.disabled-select div {
  background-color: #ffffff !important;
}
.disabled-select span {
  color: #000 !important;
}
.disabled-select input {
  color: #000 !important;
  background-color: #ffffff !important;
}
.info-text {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

