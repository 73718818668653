.loginContainer {
    width: 50%!important;
    margin: 1rem auto!important;
    padding: 10% 0!important;
    background-color: white;
    border-radius: 10px;
}
.page-wrapper-login{
    position: relative;
    min-height: 100vh!important;
    overflow: auto;
}

.page-wrapper-login .login-footer{
    background: none!important;
     color: #fff!important; 
    font-size: 1rem!important; 
}
