.ant-modal-title {
  font-size: 20px !important;
  line-height: 28px !important;
}
.comments-wrapper {
  height: 50vh;
  overflow: auto;
}
.comments-wrapper-admin {
  height: 70vh;
  overflow: auto;
}
.footer-comments {
  width: 70%;
  margin: 0 auto;
  padding: 0 !important;
}
