.app-info {
  border-radius: 5px;
  border: 1px solid #d4c8f5;
}
.app-work {
  background: #fff;
  padding: 0 1rem 1rem !important;
  width: 60%;
  max-height: 80vh;
  overflow: auto;
  position: relative;
}

.app-info-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.change-status {
  display: flex;
  justify-content: center;
  gap: 12%;
  list-style: none;
  font-weight: 400;
  padding-right: 0.5rem;
}
.change-status > li {
  position: relative;
  cursor: pointer;
}

.change-status > li:hover::before,
.change-status > li:active::before {
  position: absolute;
  font-size: 1.5rem;
  content: '•';
  color: #64f583;
  top: 0;
  transform: translate(-150%, -25%);
}

.status-selected::before {
  position: absolute;
  font-size: 1.5rem;
  content: '•';
  color: #64f583;
  top: 0;
  transform: translate(-150%, -25%);
}

.app-header {
  position: relative;
  padding-left: 1rem;
}
.back-btn {
  position: relative;
  padding-left: 1rem;
  cursor: pointer;
}
.back-btn::after {
  width: 3px;
  height: 16px;
  background-color: rgba(84, 152, 195, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  transform: rotate(45deg);
}
.back-btn::before {
  width: 3px;
  height: 16px;
  background-color: rgba(84, 152, 195, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  transform: rotate(140deg) translate(300%, -45%);
}
.chat-btn {
  text-align: end;
}
.file-link {
  margin: 5px;
  cursor: pointer;
  color: #1890ff;
}

.file-link:hover {
  color: #40a9ff;
}
.file-link:active {
  color: #096dd9;
}

.inwork-select {
  width: 300px !important;
}
.application-submit-btn {
  width: 200px;
}
.aplication-additional-btns {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .app-work {
    width: 100%;
    height: 80vh;
    border: 1px solid;
    border-bottom: none;
    padding-top: 0.5rem !important ;
    border-radius: 10px;
  }
}
