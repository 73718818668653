.application-menu-wrapper {
  background: #fff;
  width: 15%;
  display: flex;
  flex-direction: column;
}

.application-menu > .ant-menu-item {
  margin: 2rem 0.2rem !important;
  width: 95% !important;
  border: 2px solid rgba(84, 152, 195, 0.5);
  text-decoration: black;
  border-radius: 10px;
}

.application-menu > .ant-menu-item:hover {
  background-color: rgba(84, 152, 195, 0.7);
  color: #000 !important;
}
.application-menu > .ant-menu-item-selected {
  color: #000 !important;
  background-color: rgba(84, 152, 195, 0.3) !important;
}
.application-menu > .ant-menu-item-selected::after {
  opacity: 0 !important;
}
.menu-filter-btns {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 0 1rem;
}
.search-app-form {
  margin-top: 10px !important;
}

@media screen and (max-width: 1050px) {
  .application-menu-wrapper {
    width: 20%;
  }
}
