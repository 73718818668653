.app-wrap {
  max-height: 75vh !important;
  width: 80%;
}
.app-wrap-flex {
  display: flex;
  justify-content: space-around;
}
.list {
  min-width: 300px;
}
.overflow {
  overflow: auto;
}
.application-header {
  margin: 0 1rem;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.width-30 {
  width: 30%;
}

.width-80 {
  width: 80%;
}

@media screen and (max-width: 700px) {
  .app-wrap-flex {
    flex-direction: column;
    overflow: auto;
    gap: 20px;
  }
  .width-30 {
    width: 100%;
    max-height: 40vh;
  }
}
