.statistics-cell {
  word-wrap: break-word;
  border: 1px solid rgba(84, 152, 195, 0.7);
  padding: 5px 10px;
}
.statistics-row {
  margin: 10px 0px;
}
.statistics-header {
  font-weight: 700;
  width: 50%;
}
.statistics-header-wrapper {
  display: flex;
  gap: 10px;
}
