.application-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2rem 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid rgba(84, 152, 195, 0.7);
  text-decoration: black;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.application-card:nth-child(2) {
  margin-top: 1rem;
}
.application-card:hover,
.application-card:active {
  border-color: rgba(84, 152, 195, 0.3);
}
.application-card-selected {
  background-color: rgba(84, 152, 195, 0.3);
}
.application-point {
  display: flex;
  justify-content: space-between;
}
.application-point-open {
  display: flex;
  justify-content: flex-end;
  gap: 10%;
}
.date-update {
  justify-self: flex-start;
  align-self: start;
  margin-right: auto;
}
.application-on-test {
  font-size: 20px;
  line-height: 22px;
  color: #5499c3f6;
}
.waiting-answer {
  color: #f08080;
}
.card-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.application-card-small {
  height: var(--card-height-small);
}
.application-card-big {
  height: var(--card-height-big);
}

@media screen and (max-width: 700px) {
  .application-card {
    justify-content: space-around;
  }
  .application-card-big {
    height: auto;
  }
}
@media screen and (min-width: 1400px) {
  .application-card {
    justify-content: space-around;
  }
  .application-card-big {
    height: auto;
  }
}
