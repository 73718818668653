.loading-modal {
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* -ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%); */
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
