.user-header {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    width: 90%;
    margin: 0 auto;
    transform: translateY(-25%);
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after{
    border-bottom: 0px!important;
}
.box{
    height: 70px;
    overflow:hidden;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.box img{
   width:60px;
   height:60px;
   border-radius: 50%;
   object-fit:cover;
}
.box p{
    position: relative;
    top: 10%;
}