.comment {
  color: #000 !important;
}
.comment > * span {
  color: #2b2b2b !important;
  font-size: 12px;
}
.comment-text {
  font-size: 16px;
}
